import * as React from 'react';
import {
  Box,
  Paper, 
  Typography,
} from '@mui/material';

export default function Banner() {
  return (
    <Box>
      <Paper elevation={3}>
        <Typography variant="h3" component="h1" align='center' padding={2} marginY={14} >
          Are You a fish fan?
        </Typography>
      </Paper>
    </Box>
  );
}