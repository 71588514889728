import * as React from 'react';
import {
  Box,
  Container,
  Typography, 
} from '@mui/material';

import Banner from './components/Banner';
import Copyright from './components/Copyright';

export default function App() {
  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Box sx={{ my: 4 }}>
        <Banner />
        <Typography variant="h4" component="h1" align='center' padding={2}>
          A new website is comming soon!
        </Typography> 
        <Copyright />
      </Box>
    </Container>
  );
}